import React from "react";
import classnames from "classnames/bind";
import styles from "../../../styles/Home.module.scss";

const cx = classnames.bind(styles);

interface props {
  color: "blue" | "orange";
  title: string;
}

const TitleHomeSection = ({ color, title }: props) => {
  return (
    <>
      {color === "blue" ? (
        <div className={cx("title-section-primary")}>
          <h2 className="fs-3 fw-semibold">{title}</h2>
        </div>
      ) : color === "orange" ? (
        <div className={cx("title-section-secondary")}>
          <h3 className="fs-3 fw-semibold">{title}</h3>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default TitleHomeSection;
