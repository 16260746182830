import useTranslation from "next-translate/useTranslation";
import styles from "../styles/Home.module.scss";
import classnames from "classnames/bind";
import Image from "next/image";
import { useStateContext } from "../context";
import Head from "next/head";
import dynamic from "next/dynamic";
import Link from "next/link";
import WhySectionHome from "../components/home/section/whySectionHome";
import HomeSectionItem from "../components/home/section/homeSection";
import ContainerPage from "../components/layout/containerPage";

const SignInForm = dynamic(() => import("../components/forms/signIn"), {
  ssr: false,
});

const cx = classnames.bind(styles);

export default function Home() {
  const { t } = useTranslation("home");

  const [{ isAuthorized }] = useStateContext();

  return (
    <ContainerPage>
      <Head>
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
        <meta property="og:image" content={"/images/og-logo.png"}></meta>
        <link rel="canonical" href={process.env.BASE_URL} />
      </Head>
      <section className={cx("main-section")}>
        <div className={cx("banner-container")}>
          <div className={cx("pen-container")}>
            <Image
              src="/images/pen.svg"
              layout="fill"
              objectFit="contain"
              objectPosition={"top"}
              quality="75"
              alt={t("main_heading")}
            />
          </div>
          <div className={cx("image-container")}>
            <Image
              src="/images/main-home.svg"
              layout="fill"
              objectFit="cover"
              objectPosition={"top"}
              quality="75"
              alt={t("main_heading")}
            />
          </div>
          <div className={cx("image-container2")}>
            <Image
              src="/images/main-home2.svg"
              layout="fill"
              objectFit="cover"
              quality="75"
              alt={t("main_heading")}
            />
          </div>
          <div className={cx("container-form")}>
            <div className={cx("button-side")}>
              <div className={cx("item-button-side")}>
                <Link href={"https://www.kitakerja.com/"}>{t("Job")}</Link>
                <Link href={"https://blog.kitakerja.com"}>{t("Article")}</Link>
              </div>
            </div>
            <div
              className={cx(
                "banner-content",
                "container d-flex w-100  h-100 align-items-center"
              )}
            >
              <div className={cx("left-banner-side")}>
                <div className={cx("title-banner")}>
                  <div
                    className={cx("d-flex align-items-center my-3 w-100", {
                      "col-md-6": !isAuthorized,
                      col: isAuthorized,
                    })}
                  >
                    <div className={cx("title-main-container")}>
                      <div className={cx("title-image")}>
                        <Image
                          src={"/images/shape-title.svg"}
                          width={193}
                          height={63}
                          alt="shape"
                        />
                      </div>
                      {/* <h1 className="">{t("main_heading")}</h1> */}
                      <h1>
                        <span className={cx("title-main", "text-nowrap")}>
                          {t("title_main_heading")}
                        </span>
                        <span
                          className={cx(
                            cx("sub-title-main"),
                            "text-capitalize w-100 text-wrap",
                            {
                              "text-center": isAuthorized,
                            }
                          )}
                        >
                          {t("sub_main_heading")}
                        </span>
                      </h1>

                      <p className={cx("text-justify pt-4")}>
                        {t("description_heading")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx("container-board-side")}>
                {!isAuthorized && (
                  <div className="w-100">
                    <SignInForm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhySectionHome />

      <div className={cx("home-sections")}>
        <div className="container">
          <div className={cx("home-section-container", "container")}>
            <HomeSectionItem
              imageLink="/images/home-advertise.svg"
              description={t("section#1_description")}
              title={t("section#1_title")}
            />
            <HomeSectionItem
              imageLink="/images/home-candidate.svg"
              description={t("section#2_description")}
              title={t("section#2_title")}
            />
            <HomeSectionItem
              imageLink="/images/home-candidate2.svg"
              description={t("section#3_description")}
              title={t("section#3_title")}
            />
          </div>
        </div>
      </div>
    </ContainerPage>
  );
}
